import './App.css';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import {useAuthState} from 'react-firebase-hooks/auth';
import config from './config.json';
import vars from './vars'
import {Octokit} from '@octokit/core';
const maintainers = require('./maintainers');

firebase.initializeApp({
  apiKey: config.key,
  authDomain: "is-a-dev-maintainers.firebaseapp.com",
  projectId: "is-a-dev-maintainers",
  storageBucket: "is-a-dev-maintainers.appspot.com",
  messagingSenderId: "980243480077",
  appId: "1:980243480077:web:20a90dc1ea1b9c0f471f44",
  measurementId: "G-S2CG66FL69"
});
const auth = firebase.auth();
const githubLoginProvider = new firebase.auth.GithubAuthProvider();
//auth.signInWithPopup(provider)

function SignIn() {

  return (
    <button className='button signIn' onClick={()=> {
      githubLoginProvider.addScope('repo');  
      auth.signInWithPopup(githubLoginProvider).then((res)=>{
       console.log(res.credential.accessToken);
       vars.token = res.credential.accessToken
       vars.user = res.additionalUserInfo.username;
       console.log(res.additionalUserInfo.username);
       vars.email = res.user.email;

       Object.freeze(vars);
       document.getElementById('ghMessage').innerText = `Logged In As ${res.additionalUserInfo.username}`;
      })
    }}>Sign In With GitHub</button>
  );
}

function SignOut() {
  return (
    <button className='signOut button' onClick={() => auth.signOut()}>Sign Out</button>
  )
}

function App() {
  window.addEventListener('load', (event) => {
    auth.signOut()
  });
  const [user] = useAuthState(auth);

  return (
    <div className="App">
      <header>
        <h1>Welcome to @is-a-dev/maintainers!</h1>
        <h4 id="ghMessage">Please Login With Your GitHub Account!</h4>
      </header>
      <main>
        {user ? <Maintainers /> : <SignIn />}
      </main>
    </div>
  );
}

function Maintainers() {
  if(!maintainers.includes(vars.email)) {
    return (
      <div>
        <h1>You Do Not Have Access To This Site!</h1>
      </div>
    )
  } else return (
    <div>
      <button onClick={() => {
        const octokit = new Octokit({
          auth: vars.token
        });
        
        const res = octokit.request('GET /repos/{owner}/{repo}/pulls', {
          owner: 'is-a-dev',
          repo: 'register'
        });
        res.then((response) => response.data.forEach(pr => {
          if(!pr.labels.find(label => label.name === 'status: pending fix')) {
            document.getElementById('prs').innerHTML = `<h6>
            <a href='${pr.html_url}'>PR #${pr.number}</a>
          </h6>`
          } else return;
        }));
      }}>Get PR</button>
      <br />
      <SignOut />
      <h2>Look at the following PR (does not have pending fix label): </h2>
      <div id='prs'></div>
    </div>
  )
}

export default App;
